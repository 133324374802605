@layer components {
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    animation: spin var(--spinner-speed) linear infinite;
    width: var(--spinner-size);
    height: var(--spinner-size);
    border-radius: 100%;
    border: var(--spinner-width) solid var(--spinner-accent-color);
    border-top-color: transparent;
  }

  .primary {
    border-color: var(--spinner-accent-color);
    border-top-color: transparent;
  }

  .inverted {
    border-color: var(--color-white);
    border-top-color: transparent;
  }
}
